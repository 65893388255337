.projectTile {
  height: 100%;
  width: 100%;
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.justifyContenteven{
  justify-content: space-evenly;
}
.justifyContentodd{
  justify-content: center;
  margin: 0 0 0 2rem;
  padding-right: 3rem;
}
.justifyContentCenter{
  justify-content: center;
}
.projectTile_container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.projectTile_text {
  color: #fff;
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 01vw;
}

.projectTile_Tile {
  height: auto;
  width: 200px;
  margin-bottom: 2rem;
  margin-right: 2rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 16vw;
  border-radius: 10px;
  border: 1.5px solid #655445;
  backdrop-filter: blur(10px);
}

.projectTile_button {
  width: 70%;
  border: 2px solid #6875f8;
  font-size: 1.2rem;
  border-radius: 8px;
  background-color: #3f4bcc;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}
.projectTile_button_disabled {
  width: 70%;
  border: 2px solid #484849;
  font-size: 1rem;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  background-color: #5e5e63;
  cursor: pointer;
}
.projectTile_buttonText {
  margin-top: 0.5rem;
}
