.homepage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "Pixellari";
}

.homepage_header {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 324px;
}

.logo {
  width: 50vw;
}

.description {
  color: #ffffff;
  font-size: 2rem;
}

.wallet_connection {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8vh;
}

.wallet_section {
  width: 1116px;
  height: 290px;
  border: 3px white dashed;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.connect_wallet {
  padding: 32px 55px;
  color: white;
  background: #b348d4;
  border: none;
  border-radius: 40px;
  font-family: "Pixellari";
  font-size: 28px;
  text-decoration: none;
}

.wallet_description {
  font-size: 26px;
  color: #ffffff;
  font-weight: normal;
}

.connect_wallet:hover {
  color: #ffffff;
}
