html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Pixellari";
  src: local("Pixellari"),
    url("fonts/Pixellari/Pixellari.ttf") format("truetype");
  font-weight: normal;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn{
  background: gray !important;
}