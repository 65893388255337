.card {
  height: 31rem;
  width: 19rem;
  padding: 1.2rem 1.5rem;
  background: url("../../src/assets/img/Path\ 736.png") no-repeat;
  backdrop-filter: blur(7px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: stretch;
}
.price-loading-size{
  height: 20px;
  width: 20px;
}

.no-item-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.card_img {
  height: 16rem;
  width: 16rem;
}
.bottom-right-text {
  width: 1.5rem;
  height: 1.5rem;

  background: url("../../src/assets/img/info_icon.svg") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.price_div {
  background: url("../../src/assets/img/price-bg.svg") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}
.price-value {
  color: #0fea4a;
}
.buy_btn {
  background: url("../../src/assets/img/button-bg.png") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  height: 3.2rem;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
  padding-top: 10px;
}
.right-text {
  right: -2px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 30% 100%);
}
.point_text {
  min-width: 43%;
  text-align: center;
  transform: rotate(-40deg);
  top: 3px;
  left: -2.2rem;
  clip-path: polygon(20% 0, 75% 0%, 100% 100%, 0% 100%);
}
.card-footer-bg {
  background: url("../../src/assets/img/card_footer_bg.png") no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.card-footer-bg span {
  color: #f99a3c;
  font: 12px "Pixellari";
}
.card-footer-bg h5 {
  font: 15px "Pixellari";
  margin-bottom: 0 !important;
}
.proceed_button {
  background: url("../../src/assets/img/button-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 14rem;
  font-size: 2rem;
  letter-spacing: 1px;
}
.buy_modal {
  background: url("../../src/assets/img/modal_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-right: 3px solid rgb(85, 56, 88);
}
@media only screen and (min-width: 992px) {
  .buy_modal {
    background-size: contain;
    border-right: none;
  }
}
.modal_close {
  background: url("../../src/assets/img/modal_close_button.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  right: -8px;
  top: -8px;
}
.modal-content {
  background-color: transparent;
}
.modal_contents p {
  text-align: justify;
}
.top-25{
  top: 25px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5px 7px;
  border-radius: 5px;
}
.blur-image{
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.blur-text {
  color: red;
  font-weight: bold;
  border: 6px solid red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
  font-size: 40px;
}