.dashboard {
  width: 100%;
  height: 50rem;
  justify-content: space-around;
  font-family: "Pixellari";
  margin-bottom: 8px;
}
.dashboard_logo {
  width: 100%;
  height: auto;
}
.w-25 {
  width: 20%;
}
.dashboard_header {
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wallet_bal {
  color: #f99a3c;
  text-align: right;
  width: 100%;
  font-size: 1.4rem;
  margin-top: 1rem;
}
.wallet_max {
  color: #f99a3c;
  width: 40%;
  font-size: 1.4rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 767px) {
  .dashboard_header {
    height: 8rem;
    width: 100%;
    display: inline-block;
    flex-wrap: wrap;
    overflow-y: hidden;
    justify-content: space-evenly;
    align-items: center;
  }
}
.wallet_details {
  width: 80%;
  height: 100%;
  padding-top: 1rem;
}

.breath_information {
  display: flex;
}
.dashboard_loader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.wallet_balance {
  color: white;
  margin-top: 1rem;
  margin-left: 0.5rem;
  text-align: left;
  width: 50%;
  font-size: 1.4rem;
}

.wallet_type {
  color: #d14147;
  width: 38%;

  font-size: 1.4rem;
  margin-top: 1rem;
}
.wallet_type_button {
  color: #fff;
  height: 2.8rem;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  border: 2px solid #e881ff;
  padding: 5px 15px;
  border-radius: 5px;
}
.wallet_type_button:hover {
  transition: 0.3s;
  cursor: pointer;
  color: #e881ff;
  border: 2px solid #d14147;
}

.wallet_address {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: white;
  margin-bottom: 0;
}

.card_gallery {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 90vw;
  height: 644px;
  overflow-x: scroll;
  padding: 4px 0;
  margin: 4px 0 0 30px;
}
.btn-connect {
  white-space: nowrap;
  font-size: 1.2rem;
  outline: none;
  border: none;
  border-radius: 10px;
  color: #e881ff;
  margin-bottom: 0;
}
.owned {
  height: 548px;
}

.pending_requests_card {
  height: 690px;
}

.menu_items {
  color: #b4b4b4 !important;
  font-size: 28px;
  background-color: transparent;
  border: none;
  margin: 0 10px;
}

.selected_menu_item {
  color: #00ffd8 !important;
}

.dashboard_main {
  overflow: hidden;
  height: 90%;
  color: #ffffff;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}

.dashboard_options {
  display: flex;
  margin-left: 7.5rem;
  flex-direction: row;
}

.dashboard_option {
  padding: 1rem;
  padding-bottom: 0.2rem;
  cursor: pointer;
}

.selected {
  border-radius: 10px 10px 0px 0px;
  background-color: #3f4bcc;
}

.dashboard_container {
  margin: -0.5rem 5vw 0 5vw;
  border-radius: 10px;
  border: 3px solid #3f4bcc;
  overflow: hidden;
  width: 90vw;
  height: 90%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  padding-left: 0;
}

.navigation_menu {
  width: 90vw;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  backdrop-filter: blur(10px);
  width: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: white;
  width: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece {
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  width: 5px;
}

::-webkit-resizer {
  width: 5px;
}

.breeding_info {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  margin-top: 10px;
}

.navigation_button {
  background-color: #00ff27;
  font-size: 13px;
  color: #000000;
  padding: 14px 24px;
  border-radius: 23px;
  border: none;
  margin: 5px 0;
  width: 200px;
  height: 48px;
  text-decoration: none;
}

.navigation_button:hover {
  color: #000000;
}

.empty_wyverns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;
  height: 450px;
  margin-top: 100px;
}

.empty_inventory_message {
  color: #ffffff !important;
  font-size: 28px;
}

.inventory_link:before {
  content: "\2190";
}

.inventory_link {
  color: #ffffff;
  text-decoration: none;
  width: 90vw;
  text-align: left;
  margin-left: 52px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.inventory_link:hover {
  color: #b4b4b4;
}

.breed_owned {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
}

.claim_breath_button {
  border: none;
  background-color: #b348d4;
  font-size: 13px;
  color: white;
  border-radius: 22px;
  padding: 14px 24px;
}

.claim_breath {
  font-size: 13px;
  padding: 14px 24px;
  border-radius: 23px;
  border: none;
  background-color: #b348d4;
  margin: 5px 0 10px;
  color: white;
  height: 48px;
}

.empty_pending_request_message {
  color: #ffffff !important;
  font-size: 28px;
  margin-top: 40px;
  width: 100%;
  text-align: center;
}
.connected-string {
  padding: 7px 15px;
  margin-right: 1rem;
}
.connected-string:hover {
  background-color: #b348d4;
  color: #fff;
}
.dropdown-item-menu {
  transform: translate3d(20px, 45px, 0px) !important;
  color: #000;
  overflow: hidden;
}
.dropdown-item {
  padding-left: 0.7rem;
}
.dropdown-menu {
  min-width: 6rem !important;
  padding-bottom: 0;
}
.dropdown-divider {
  margin: 0 !important;
  position: relative;
  left: 0.8rem;
  background-color: #000 !important;
}

.filter_button {
  background-color: #000 !important;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
}
.filter_button img {
  height: 2rem;
  width: 2rem;
}
.dropdown-toggle::after {
  display: none;
}
.btn-dark:active:focus {
  box-shadow: none !important;
}
