.projectDetail {
  height: auto;
  width: 17rem;
  padding-top: 1vw;
  padding-left: 1vw;
  border-radius: 10px;
  border: 1.5px solid #655445;
  backdrop-filter: blur(10px);
  overflow-y: auto;
}

.projectDetail_textfilter {
  text-align: left;
  font-size: 1.8rem;
}

.projectDetail_textheading {
  text-align: left;
  font-size: 1.6rem;
}

.projectDetail_text {
  color: #fff;
  font-size: 1.3rem;
  margin-bottom: 0;
}

.projectDetail_project {
  display: flex;
  height: 40px;
  font-size: 2rem;
  width: 100%;
  flex-direction: row;
}

.checked {
  background-color: #fff;
}
