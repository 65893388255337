.projectPurchase {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
}

.projectPurchase_loader {
  margin: auto auto;
  justify-content: center;
}
.projectPurchase_scrollview {
  margin-left: 1rem;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.projectPurchase_text {
  width: 10rem;
  color: #fff;
  font-size: 1.3vw;
}
.projectPurchase_text:first-letter {
  text-transform: uppercase;
}
.projectPurchase_whitelist {
  font-size: 1.5rem;
  color: #f99a3c;
}
.projectPurchase_spot {
  font-size: 1.2rem;
  margin-top: 4rem;
  height: 2rem;
  width: 7rem;
  margin-left: 2.5rem;
  /* background-color: aliceblue; */
  transform: matrix(0, -1, 1, 0, 0, 0);
}
.projectPurchase_img {
  width: 5rem;
  height: 5rem;
  margin-top: -10px;
}
.nake-perfect {
  align-items: center;
  justify-content: center;
}
.projectPurchase_Tile {
  background-image: url("./purchase.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 26rem;
  height: 11rem;
  padding-left: 1.5rem;
  /* padding-top: 1rem; */
  display: flex;
  margin-right: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  backdrop-filter: blur(7px);
}

.projectPurchase_button {
  height: 4vw;
  width: 70%;
  padding-top: 20;
  border: 2px solid #6875f8;
  font-size: 1.8vw;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  margin-left: 15%;
  background-color: #3f4bcc;
}
.projectPurchase_buttonText {
  margin-top: 0.3rem;
}
/* New card */
.cardContainer {
  height: 25rem;
  width: 18rem;
  padding: 1.2rem 1.5rem;
  background: url("../card-bg.svg") no-repeat;
  backdrop-filter: blur(7px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: stretch;
}
.newCard_logo {
  height: 15rem;
  width: 15rem;
}
.newCard_headerText {
  width: 100%;
  margin: 0.5rem 0;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
}
.newCard_button {
  width: 15rem;
  height: 3.5rem;
  text-align: center;
  color: white;
  font-size: 1.3rem;
  /* border-bottom-right-radius: 1.5rem; */
  background: url("../button-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: stretch;
  outline: none;
  border: none;
}
